import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import Home from 'pages/Home';
import Login from 'pages/Login';
import SSO from 'pages/SSO';
import ChangePassword from 'pages/ChangePassword';
import UnlockRequest from 'pages/UnlockRequest';
import ProfileSetting from 'pages/ProfileSetting';
import PageNotFound from 'pages/PageNotFound';
import Section from 'pages/Section';
import Introduction from 'pages/Introduction';
import MFA from 'pages/MFA';
import ForgotPassword from 'pages/ForgotPassword';
import TermsOfUse from 'pages/TermsOfUse';
import Log from 'pages/Log';
import Announcement from 'pages/Announcement';
import Layout from 'components/Layout';
import { hot } from 'react-hot-loader';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import 'element-theme-default';
import { STORAGE_KEY_APP_SYNC_SIGNED_IN, STORAGE_KEY_LAST_ACTIVE_TIME } from 'core/constants';
import { Callback, PresignedURL } from './pages/Login';
// import WebChatComponent from './scripts/useWebchat';

// Support new line with \n in toast body
const StyledContainer = styled(ToastContainer)`
  .Toastify__toast-body {
    white-space: pre-line;
  }
`;

function App() {
  useEffect(() => {
    // Login/logout in multiple tabs
    window.addEventListener('storage', e => {
      const { key } = e;
      if (key !== STORAGE_KEY_APP_SYNC_SIGNED_IN) return;
      window.location.reload();
    });
    if (
      [
        '/login',
        '/tci',
        '/saml/idp/sso',
        '/change-password',
        '/unlock-request',
        '/mfa',
        '/forgot-password',
        '/auth/callback'
      ].includes(window.location.pathname)
    ) {
      localStorage.removeItem(STORAGE_KEY_LAST_ACTIVE_TIME);
    }
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/tci" component={Login} />
        <Route exact path="/auth/callback" component={Callback} />
        <Route exact path="/auth/presignedURL" component={PresignedURL} />
        <Route exact path="/saml/idp/sso" component={SSO} />
        <Route exact path="/change-password" component={ChangePassword} />
        <Route exact path="/unlock-request" component={UnlockRequest} />
        <Route exact path="/mfa" component={MFA} />
        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route exact path="/terms-of-use" component={TermsOfUse} />
        <Route exact path="/saml/idp/terms-of-use" component={TermsOfUse} />
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/profile-settings" component={ProfileSetting} />
            <Route exact path="/access-history" component={Log} />
            <Route exact path="/section/:sectionId" component={Section} />
            <Route exact path="/introduction" component={Introduction} />
            <Route path="*" component={PageNotFound} />
          </Switch>
        </Layout>
      </Switch>
      <StyledContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnHover
      />
      {/* <WebChatComponent /> */}
    </>
  );
}

export default (process.env.NODE_ENV === 'development' ? hot(module)(App) : App);
