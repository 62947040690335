//import React, { Component } from 'react';
import 'assets/scss/theme/login.scss';
import './index.scss';
import fb from 'core/firebase';
import ModalBox from 'components/ModalBox';
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { auth as authService } from 'services';
import { user as userService } from 'services';
import { resource as resourceService } from 'services';
import { Loading } from 'element-react';
import { logger } from 'core/logger';
import validator from 'core/validator/customValidator';
import { FormFeedback, Row, Col } from 'reactstrap';
import BaseInput from 'components/BaseInput';
import BaseInputPassword from 'components/BaseInputPassword';
import { cookies, resetLocalStorage } from 'core/utils';
import BaseSelect from 'components/BaseSelect';
//import Domain from './domain';
import Domain from 'pages/Login/domain';
import { Link } from 'react-router-dom';
import {
  COOKIE_KEY_USER_TOKEN,
  STORAGE_KEY_APP_SYNC_SIGNED_IN,
  COOKIE_KEY_APP_ATM_TOKEN,
  COOKIE_KEY_APP_ATM_TOKEN_EXP,
  STORAGE_KEY_LAST_ACTIVE_TIME,
  COOKIE_KEY_REFRESH_TOKEN,
  MODE_LOGIN,
  maxLengthPassword
} from 'core/constants';
import { Copyright, Announcement, Confirm } from 'components';
import i18next from 'i18n';
import { encodeBase64 } from 'core/helpers';

function Login(props) {
  const {
    history,
    updateFullLoading,
    fullloading,
    updateNotification,
    notification,
    modeLogin,
    setLoginIdStore,
    setModeLogin
  } = props;
  const { t } = useTranslation();
  const baseURL = process.env.REACT_APP_TCI_IAM_API_URL;

  const languages = [
    {
      value: 'en_US',
      label: 'English'
    },
    {
      value: 'ja_JP',
      label: '日本語'
    },
    {
      value: 'zh_TW',
      label: '繁體中文'
    }
  ];

  const loginModes = [
    {
      value: MODE_LOGIN.LOGIN_BY_LOGIN_ID,
      label: t('common.label.login.tci')
    },
    {
      value: MODE_LOGIN.LOGIN_BY_EMAIL,
      label: t('common.label.login.other')
    }
  ];

  const lang = localStorage.getItem('sysLanguage') || 'en_US';
  const langValue = languages.filter(a => a.value === lang)[0];
  const [sysLanguage, setSysLanguage] = useState(langValue);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [errorPassword, setErrorPassword] = useState('');
  const [application, setApplication] = useState(null);
  const mountedRef = useRef(true);
  const [isDomainForm, setIsDomainForm] = useState(false);

  logger.info('=====SSO====', application);
  useEffect(() => {
    async function fetchData() {
      logger.info('fetchData');
      let idpid;
      let redirectSSO;
      let appInfo;
      try {
        updateFullLoading(true);
        const searchParams = new URLSearchParams(window.location.search);
        idpid = searchParams.get('idpid');
        redirectSSO = searchParams.get('redirectSSO');
        if (!idpid) {
          return history.push('/tci');
        }

        const token = cookies.getKey(COOKIE_KEY_USER_TOKEN);
        logger.info('Not Google redirect process.', token);
        if (!modeLogin) {
          setModeLogin(MODE_LOGIN.LOGIN_BY_LOGIN_ID);
        }
        if (token) {
          appInfo = await resourceService.getSamlApp(idpid, false);
          if (appInfo) {
            const { forceReAuthentication = false } = appInfo;
            if (!forceReAuthentication || (forceReAuthentication && redirectSSO)) {
              let url = `${baseURL}/saml/idp/sso${window.location.search}`;
              const { mfa = false } = await userService.getUser();
              if (!mfa) {
                return updateNotification({
                  showModal: true,
                  heading: t('common.message.confirm.header'),
                  noCancel: true,
                  displayText: t('common.message.enforce.remind.user.setup.mfa'),
                  type: 'confirm',
                  buttonOkText: t('common.button.skip'),
                  modalClassName: 'mfa-modal-notification custom-modal',
                  callback: async closeModal => {
                    updateFullLoading(true);
                    closeModal();
                    try {
                      await resourceService.verifyPolicy(appInfo.resourceId);
                    } catch (error) {
                      await handleLoginError(error, appInfo, idpid);
                      updateFullLoading(false);
                      return;
                    }
                    if (cookies.getKey(COOKIE_KEY_USER_TOKEN))
                      url = `${url}&token=${cookies.getKey(COOKIE_KEY_USER_TOKEN)}`;
                    window.location.replace(url);
                    updateFullLoading(false);
                  }
                });
              }

              await resourceService.verifyPolicy(appInfo.resourceId);
              if (cookies.getKey(COOKIE_KEY_USER_TOKEN)) url = `${url}&token=${cookies.getKey(COOKIE_KEY_USER_TOKEN)}`;
              window.location.replace(url);
              return;
            }
          } else {
            return toast.warning(t('common.message.resource.notFound'));
          }
        }
        await iniSignIn(appInfo, idpid);
      } catch (err) {
        logger.error('useEffect => error', err);
        let errCode = err.code;
        if (!errCode) {
          if (err.response && err.response.data && err.response.data.code) {
            errCode = err.response.data.code;
          }
        }
        appInfo = !appInfo ? { idpid } : appInfo;
        if (!appInfo && !idpid) {
          return history.push('/tci');
        }
        await handleLoginError(err, appInfo, idpid);

        // Carefully: Case login unsuccessful, clear store & session can be impact to another window (tab or session) in same brower
        if (['out-of-working-hours'].includes(errCode)) {
          await iniSignIn(null, idpid);
        } else if (!['permission_denied'].includes(errCode)) {
          resetLocalStorage();
          cookies.removeAllTokens();
          setLoginIdStore(null);
          localStorage.removeItem('requiredLock');
          await iniSignIn(null, idpid);
        }
      } finally {
        updateFullLoading(false);
      }
    }
    fetchData();
    return () => {
      mountedRef.current = false; // clean up function
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const wait = async ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const iniSignIn = async (application, idpid) => {
    let appInfo = application;
    if (!appInfo) {
      appInfo = await resourceService.getSamlApp(idpid, false);
    }

    const { forceReAuthentication = false } = appInfo || {};
    const token = cookies.getKey(COOKIE_KEY_USER_TOKEN);

    if (!forceReAuthentication) cookies.removeKey(COOKIE_KEY_USER_TOKEN);

    if (token && forceReAuthentication) {
      await wait(4000).then(async () => {
        resetLocalStorage();
        cookies.removeAllTokens();
        await userService.removeUserToken(token);
      });
    }
    setApplication(appInfo);
  };

  const validateInput = (email, pass) => {
    let result = true;
    if (!pass) {
      setErrorPassword('common.validator.isRequired');
      result = false;
    }
    const keyErrMsgEmail = validateEmail(email);
    result = !keyErrMsgEmail;
    if (!result) {
      toast.warning(t('common.message.invalidInputs'));
    }
    return result;
  };

  const validateEmail = email => {
    let keyErrMsg = '';
    if (!email) {
      keyErrMsg = 'common.validator.isRequired';
    } else if (!validator.isCorrectEmail(email) && MODE_LOGIN.LOGIN_BY_EMAIL === modeLogin) {
      keyErrMsg = 'common.validator.invalidEmail';
    }
    setErrorEmail(keyErrMsg);
    return keyErrMsg;
  };

  const updateEmail = email => {
    validateEmail(email);
    setEmail(email);
  };

  const updatePassword = password => {
    let err = '';
    if (!password) {
      err = 'common.validator.isRequired';
    }
    setErrorPassword(err);
    setPassword(password);
  };

  const loginSuccess = async (data, updateTokenStorage = true) => {
    let {
      code = '',
      userToken = '',
      refreshToken = '',
      atmToken = '',
      atmTokenExpiresAt = '',
      atmTokenExpiration = 0,
      mfa,
      mfaMethod
    } = data || {};
    if (code === 'otp-required') {
      const ttl = Math.floor(new Date().getTime() / 1000) + 30;
      localStorage.setItem(email, ttl);
      setLoginIdStore(email);
      localStorage.setItem('mfa', mfa);
      localStorage.setItem('mfaMethod', mfaMethod);
      localStorage.setItem('action', 'LOGIN');
      localStorage.removeItem('mfaRetry');
      localStorage.setItem('mfaLoginTimestamp', Math.round(Date.now() / 1000));
      history.push(`/mfa?resourceId=${application.resourceId}&redirect=${encodeURIComponent(window.location.href)}`);
    } else {
      localStorage.setItem('clearWebchat', true);
      localStorage.setItem('action', 'LOGIN');
      localStorage.setItem(STORAGE_KEY_APP_SYNC_SIGNED_IN, new Date().getTime());
      localStorage.setItem(STORAGE_KEY_LAST_ACTIVE_TIME, new Date().getTime());
      if (updateTokenStorage) {
        cookies.setKey(COOKIE_KEY_USER_TOKEN, userToken);
        cookies.setKey(COOKIE_KEY_REFRESH_TOKEN, refreshToken);
        cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN, atmToken);
        cookies.setKey(COOKIE_KEY_APP_ATM_TOKEN_EXP, atmTokenExpiresAt);
      }
      try {
        const userInfo = await userService.getUser();
        if (userInfo) {
          const { requireChangePassword, loginDomainProvider = '' } = userInfo;
          if (requireChangePassword && loginDomainProvider === 'email') {
            history.push(`/change-password?redirect=${encodeURIComponent(window.location.href)}`);
          } else {
            updateNotification({
              showModal: true,
              heading: t('common.message.confirm.header'),
              noCancel: true,
              displayText: t('common.message.enforce.remind.user.setup.mfa'),
              type: 'confirm',
              buttonOkText: t('common.button.skip'),
              modalClassName: 'mfa-modal-notification custom-modal',
              callback: async closeModal => {
                updateFullLoading(true);
                closeModal();
                try {
                  await resourceService.verifyPolicy(application.resourceId);
                } catch (error) {
                  await handleLoginError(error, application);
                  return;
                }
                let url = `${baseURL}/saml/idp/sso${window.location.search}`;
                if (cookies.getKey(COOKIE_KEY_USER_TOKEN))
                  url = `${url}&token=${cookies.getKey(COOKIE_KEY_USER_TOKEN)}`;
                window.location.replace(url);
                updateFullLoading(false);
              }
            });
          }
        }
      } catch (err) {
        console.error('loginSuccess -> err: ', err);
        // if Token expired or user not found: do nothing, user must login again
        await handleLoginError(err);
      }
    }
    return true;
  };

  const signIn = async e => {
    e.preventDefault();
    try {
      updateFullLoading(true);
      if (!validateInput(email, password)) return;
      setIsAuthenticating(true);
      const resourceId = application && application.resourceId ? application.resourceId : '';
      await authenticate({ email, password: window.btoa(password), resourceId });
    } catch (err) {
      logger.error('signIn -> err', err);
      await handleLoginError(err, application);
    } finally {
      setIsAuthenticating(false);
      updateFullLoading(false);
    }
  };

  const authenticate = async ({ email, password, token = '', resourceId }) => {
    let res;
    if (!token) {
      res = await authService.authenticate({ email, password, resourceId });
    } else {
      // google
      res = await authService.authenticate({ token, resourceId });
    }
    logger.info('authenticate ~ res', res);
    logger.info('application', application);
    updateFullLoading(false);
    let data = res;
    const { isChangeEmail } = data;
    if (isChangeEmail) {
      Confirm({
        message: t('Common.Alert.SMSMFA.ChangeToEmail')
      }).then(async result => {
        if (result) {
          await authService.sendOTPToEmail(email).then(_res => {
            loginSuccess(_res);
          });
        }
      });
    } else if (isChangeEmail === false) {
      toast.warning(<div dangerouslySetInnerHTML={{ __html: t('Common.Alert.SMSMFA.ContactManager') }} />);
    } else {
      loginSuccess(res);
    }
  };

  const signInWithGoogle = async e => {
    fb.signInWithGoogle(window.location.href);
  };

  const signInWithMicrosoft = async () => {
    updateFullLoading(true);
    try {
      const { url } = await authService.signInWithMicrosoft(window.location.href);
      window.location.replace(url);
      updateFullLoading(false);
    } catch (error) {
      logger.error('signInWithMicrosoft', error);
      updateFullLoading(false);
    }
  };

  const handleLoginError = async (err, appInfo, idpid) => {
    const { response } = err;
    const { data = {} } = response || {};
    let code = data.code;
    if (err.error) {
      if (typeof err.error === 'string') {
        code = err.error;
      }
      if (err.error.code) {
        code = err.error.code;
      }
    }
    logger.error('Login -> code', code);
    let warningMessage = '';
    switch (code) {
      case 'auth/user-not-found':
      case 'user-not-found':
        warningMessage = t('common.message.user.notFound');
        break;
      case 'account-is-locked':
        warningMessage = t('common.message.user.locked');
        break;
      case 'account-locked-by-wrong-email-password': {
        warningMessage = t('login.message.wrongPassword.accountLocked');
        break;
      }
      case 'domain-not-found':
        warningMessage = t('common.message.domain.notFound');
        break;
      case 'domain-inactive':
        warningMessage = t('common.message.domain.inactive');
        break;
      case 'method-not-allow':
        warningMessage = t('common.message.provider.invalid');
        break;
      case 'resource-not-found':
        warningMessage = t('common.message.resource.notFound');
        break;
      case 'not-organization':
        warningMessage = t('common.message.resource.notOrganization');
        break;
      case 'no-policy-available':
        warningMessage = t('sso.message.notification.no.permission');
        break;
      case 'ip-address-unauthorized':
        warningMessage = t('common.message.policy.ip.unauthorized');
        break;
      case 'invalid-email-or-password':
        warningMessage =
          modeLogin === 'login'
            ? t('login.message.emailOrPassword.incorrect')
            : t('login.message.idOrPassword.incorrect');
        break;
      case 'generate-otp-error':
        warningMessage = t('mfa.message.generate.otp.error');
        break;

      case 'out-of-working-hours':
        warningMessage = t('common.message.policy.outOfWorkingHour');
        setTimeout(() => {
          if (appInfo && appInfo.serviceProviderType && appInfo.serviceProviderType === 'wiki') {
            const wikiURL = process.env.REACT_APP_WIKI_URL;
            window.location.replace(wikiURL);
          }
        }, 3000);
        break;

      case 'token-expired':
      case 'invalid-token':
      case 'token-is-required':
        return iniSignIn(appInfo, idpid);
      case 'permission_denied':
        await notifyPermissionDenied(appInfo, 'permission_denied');
        return;
      case 'max-25-connection':
        warningMessage = t('Common.Error.TooManySessions');
        break;
      case 'contact-email-is-required-in-policy':
        warningMessage = t('Common.Message.ContactEmail.Required');
        break;
      case 'mfa-is-required-in-policy':
        warningMessage = t('Common.Message.MFA.Required');
        break;
      case 'server-is-maintenance':
        window.location.reload();
      default:
        toast.error(t('common.message.error'));
    }
    if (warningMessage) {
      toast.warning(warningMessage);
      if (code && ['account-is-locked', 'account-locked-by-wrong-email-password'].includes(code)) {
        setTimeout(() => {
          localStorage.setItem('requiredLock', true);
          setLoginIdStore(email);
          history.push(`/unlock-request?redirect=${encodeURIComponent(window.location.href)}`);
        }, 2000);
      }
    }
  };

  const notifyPermissionDenied = async (appInfo, errorCode) => {
    if (appInfo && appInfo.idpid) {
      updateNotification({
        showModal: true,
        heading: t('common.message.confirm.header'),
        noCancel: true,
        displayText: t('sso.message.notification.no.permission'),
        type: 'confirm',
        callback: async closeModal => {
          closeModal();
          if (!errorCode) {
            resetLocalStorage();
            cookies.removeAllTokens();
            setLoginIdStore(null);
            localStorage.removeItem('requiredLock');
            await iniSignIn(appInfo, appInfo.idpid);
          } else {
            history.push('/tci');
          }
        },
        callbackClose: async closeModal => {
          closeModal();
          // const wikiURL = process.env.REACT_APP_WIKI_URL;
          // window.location.replace(wikiURL);
          if (appInfo.serviceProviderType === 'wiki') {
            const wikiURL = process.env.REACT_APP_WIKI_URL;
            window.location.replace(wikiURL);
          } else {
            // Others resource will implement later
          }
        }
      });
    }
  };

  const gotoDomain = () => {
    setIsDomainForm(true);
  };

  const gotoLogin = () => {
    setIsDomainForm(false);
    setErrorPassword('');
    setErrorEmail('');
    setEmail('');
    setPassword('');
  };

  const gotoForgot = e => {
    e.preventDefault();
    const keyErrMsg = validateEmail(email);
    if (keyErrMsg) {
      const warningMessage = t(keyErrMsg, {
        label: modeLogin === 'login' ? t('login.login_id') : t('profile.loginId.label')
      });
      toast.warning(warningMessage);
      return;
    }
    history.push(`/forgot-password?redirect=${encodeURIComponent(window.location.href)}`);
    setLoginIdStore(email);
  };

  const changeLanguage = res => {
    const language = res.value;
    localStorage.setItem('sysLanguage', language);
    i18n.changeLanguage(language);
    const langValue = languages.filter(a => a.value === language)[0];
    setSysLanguage(langValue);
  };

  const changeModeLogin = value => {
    gotoLogin();
    setModeLogin(value);
  };

  return (
    <div className={application ? 'login-wrapper login-page' : ''}>
      {application && (
        <div className="row no-gutters">
          <div className="d-none d-sm-none d-md-block col-md-5  col-lg-7">
            <div className="background" style={{ backgroundImage: 'url(' + require('assets/img/login/bg.jpg') + ')' }}>
              <div className="bg-caption pull-bottom sm-pull-bottom text-white p-l-20 p-b-10">
                <h2 className="semi-bold text-white">{t('login.slogan')}</h2>
                <Copyright className="small" />
              </div>
              <div className="backdrop" />
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-lg-5">
            <div className="login-container bg-white">
              <div className="m-l-0 m-r-0 sm-p-l-15 sm-p-r-15 sm-p-t-40 m-p-l-0 m-p-r-0 content">
                {/* <Announcement className="small-screen-announcement" /> */}
                <div className="row">
                  <div className="col-sx-12 col-6 text-sx-center">
                    <img
                      style={{ maxHeight: '60px', marginTop: '7px' }}
                      src={require('assets/img/logo_2x.png')}
                      alt="SSO Portal"
                    />
                  </div>
                  <div className="col-sx-12 col-6 text-right text-sx-center">
                    <img
                      style={{ maxHeight: '70px' }}
                      className="img-fluid"
                      src={require('assets/img/TCI-PF-certificate.png')}
                      alt="TCI-PF System Cert t-passport"
                    />
                  </div>
                </div>
                <p className="m-t-12">{t('login.signin.title')}</p>
                <div className="flex-grow-1 d-flex justify-content-end">
                  <div style={{ width: '100%' }}>
                    <BaseSelect
                      value={modeLogin}
                      options={loginModes}
                      onChange={changeModeLogin}
                      multiple={false}
                      sortable={false}
                      allowSort={false}
                    />
                  </div>
                </div>
                {modeLogin === MODE_LOGIN.LOGIN_BY_LOGIN_ID && (
                  <Row>
                    <Col sm="12">
                      {!isDomainForm && (
                        <form
                          method="post"
                          name="login-form"
                          className="m-t-12"
                          action="#"
                          autoComplete="off"
                          onSubmit={signIn}
                        >
                          <div className="form-group form-group-default">
                            <label>{t('profile.loginId.label')}</label>
                            <BaseInput
                              type="input"
                              className="form-control"
                              name="email"
                              value={email}
                              preventAtSpecial={true}
                              onChange={updateEmail}
                              placeholder={t('placeholder.input.Enter', {
                                field: t('profile.loginId.label')
                              })}
                            />
                          </div>
                          <FormFeedback className="d-block">
                            {errorEmail
                              ? t(errorEmail, {
                                  label: t('profile.loginId.label')
                                })
                              : ''}
                          </FormFeedback>
                          <div className="form-group form-group-default">
                            <label>{t('common.password.label')}</label>
                            <BaseInputPassword
                              showStrength={false}
                              showEyeIcon={false}
                              className="form-control"
                              name="password"
                              maxLength={maxLengthPassword}
                              value={password}
                              onChange={updatePassword}
                              placeholder={t('placeholder.input.Enter', { field: t('common.password.label') })}
                            />
                          </div>
                          <FormFeedback className="d-block">
                            {errorPassword ? t(errorPassword, { label: t('common.password.label') }) : ''}
                          </FormFeedback>
                          <div className="d-flex align-items-center">
                            <div className="m-r-15">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a style={{ fontSize: '0.7rem' }} href="/forgot-password" onClick={gotoForgot}>
                                {t('common.label.forgotPassword')}
                              </a>
                            </div>

                            <div className="flex-grow-1 d-flex justify-content-end">
                              <div style={{ maxWidth: '162px', width: '100%' }}>
                                <Select
                                  value={sysLanguage}
                                  onChange={changeLanguage}
                                  options={languages}
                                  classNamePrefix="react-select"
                                  menuPlacement="top"
                                  noOptionsMessage={() => i18next.t('common.label.no.option')}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary btn-cons m-t-12 btn-block"
                            type="submit"
                            disabled={isAuthenticating}
                          >
                            {!isAuthenticating ? (
                              <span>{t('login.signin.button')}</span>
                            ) : (
                              <span>
                                {t('login.signin.button.waiting')}...
                                <i className="fa fa-spinner fa-spin	fa-lg" />
                              </span>
                            )}
                          </button>
                        </form>
                      )}
                    </Col>
                  </Row>
                )}
                {modeLogin === MODE_LOGIN.LOGIN_BY_EMAIL && (
                  <Row>
                    <Col sm="12">
                      {!isDomainForm && (
                        <form
                          method="post"
                          name="login-form"
                          className="m-t-12"
                          action="#"
                          autoComplete="off"
                          onSubmit={signIn}
                        >
                          <div className="form-group form-group-default">
                            <label>{t('login.login_id')}</label>
                            <BaseInput
                              type="input"
                              className="form-control"
                              name="email"
                              value={email}
                              preventAtSpecial={false}
                              onChange={updateEmail}
                              placeholder={t('placeholder.input.Enter', {
                                field: t('login.login_id')
                              })}
                            />
                          </div>
                          <FormFeedback className="d-block">
                            {errorEmail
                              ? t(errorEmail, {
                                  label: t('login.login_id')
                                })
                              : ''}
                          </FormFeedback>
                          <div className="form-group form-group-default">
                            <label>{t('common.password.label')}</label>
                            <BaseInputPassword
                              showStrength={false}
                              showEyeIcon={false}
                              className="form-control"
                              name="password"
                              maxLength={maxLengthPassword}
                              value={password}
                              onChange={updatePassword}
                              placeholder={t('placeholder.input.Enter', { field: t('common.password.label') })}
                            />
                          </div>
                          <FormFeedback className="d-block">
                            {errorPassword ? t(errorPassword, { label: t('common.password.label') }) : ''}
                          </FormFeedback>
                          <div className="d-flex align-items-center">
                            <div className="m-r-15">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a style={{ fontSize: '0.7rem' }} href="/forgot-password" onClick={gotoForgot}>
                                {t('common.label.forgotPassword')}
                              </a>
                            </div>

                            <div className="flex-grow-1 d-flex justify-content-end">
                              <div style={{ maxWidth: '162px', width: '100%' }}>
                                <Select
                                  value={sysLanguage}
                                  onChange={changeLanguage}
                                  options={languages}
                                  classNamePrefix="react-select"
                                  menuPlacement="top"
                                  noOptionsMessage={() => i18next.t('common.label.no.option')}
                                />
                              </div>
                            </div>
                          </div>
                          <button
                            className="btn btn-primary btn-cons m-t-12 btn-block"
                            type="submit"
                            disabled={isAuthenticating}
                          >
                            {!isAuthenticating ? (
                              <span>{t('login.signin.button')}</span>
                            ) : (
                              <span>
                                {t('login.signin.button.waiting')}...
                                <i className="fa fa-spinner fa-spin	fa-lg" />
                              </span>
                            )}
                          </button>

                          <div>
                            <button
                              className="btn btn-info btn-cons m-t-12 btn-block"
                              type="button"
                              onClick={signInWithGoogle}
                            >
                              {t('login.signin.button.google')}
                            </button>
                            <button
                              className="btn btn-info btn-cons m-t-12 btn-block"
                              type="button"
                              onClick={gotoDomain}
                            >
                              {t('login.signin.button.ldap')}
                            </button>
                            <button
                              className="btn btn-info btn-cons m-t-12 btn-block"
                              type="button"
                              onClick={signInWithMicrosoft}
                            >
                              {t('login.signin.button.microsoft')}
                            </button>
                          </div>
                        </form>
                      )}
                    </Col>
                  </Row>
                )}
                {isDomainForm && (
                  <Domain
                    gotoToLogin={gotoLogin}
                    handleLoginError={handleLoginError}
                    appInfo={application}
                    updateFullLoading={updateFullLoading}
                    loginSuccess={loginSuccess}
                  />
                )}
              </div>
              <div className="sm-p-l-15 sm-p-r-15 terms_of_use-href m-b-20" align="left">
                <Link to={'terms-of-use'} target="_blank" title={t('termsOfUse.title')}>
                  <span>{t('termsOfUse.title')}</span>
                </Link>
              </div>
              <div className="copyright-xs">
                <Copyright className="small" />
              </div>
            </div>
          </div>
        </div>
      )}
      {notification.showModal && <ModalBox />}
      {fullloading && <Loading fullscreen={true} />}
    </div>
  );
}

export default Login;
